import React from "react";
import Head from "next/head";
import styled from "styled-components";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Link from "next/link";

const Background = styled.div`
  width: 100%;
  height: 100%;
  background: url('/images/home-background.jpg') no-repeat;
  background-size: cover;
`

const StyledContainer = styled(Container)`
  height: 100%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`

const Title = styled.h1`
  margin: 0;
  font-size: 26px;

  span {
    line-height: 110px;
    font-family: Satisfy;
    font-size: 90px;
    font-weight: normal;
    letter-spacing: 5px;
  }

  @media(max-width: 600px) {
    font-size: 20px;

    span {
      line-height: 80px;
      font-size: 50px;
    }
  }
`

const Description = styled.p`
  display: none;
  margin: 0;
  font-size: 20px;
  max-width: 500px;

  @media(max-width: 600px) {
    font-size: 18px;
  }
`

const ActionButton = styled(Button)`
  margin: 30px 0 0;
  padding: 15px;
  max-width: 300px;
  font-size: 20px;

  @media(max-width: 600px) {
    max-width: 100%;
    font-size: 16px;
  }
`

export default function Home() {
  return (
    <>
      <Head>
        <title>RPRP</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Background>
        <StyledContainer>
          <Content>
            <Title>Seja bem-vindo(a) a<br /><span>Ribeirão Pires!</span></Title>
            <Description>
              Quer fazer parte da comunidade RPRP? Preencha o formulário de whitelist
              e aguarde sua aprovação!
            </Description>
            <Link href="/whitelist" passHref>
              <ActionButton color="primary" variant="contained" size="large" disabled>
                Fechado
              </ActionButton>
            </Link>
          </Content>
        </StyledContainer>
      </Background>
    </>
  )
}
